<template>
  <b-sidebar
    id="add-new-formula-sidebar"
    :visible="isAddNewFormulaSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="formValidation().resetForm"
    @change="(val) => change(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("addRation") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblankformula
    ).refFormObserver"
      >
        <component
          :is="TinyFormulaAdd"
          :handle-submit="handleSubmit"
          :hide="hide"
          :form-validation="formValidation"
          @onSubmit="onSubmit"
        ></component>
        <!-- Form -->
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BFormGroup } from "bootstrap-vue";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import TinyFormulaAdd from "../FormulaAddForm/TinyFormulaAdd.vue";
export default {
  components: {
    BSidebar,
    BFormGroup,
    ValidationObserver,
    vSelect,
    TinyFormulaAdd,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewFormulaSidebarActive",
    event: "update:is-add-new-formula-sidebar-active",
  },
  props: {
    isAddNewFormulaSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      TinyFormulaAdd,
      required,
      blankRationData: {},
      oran: true,
      formValidation: formValidation,
      products: [],
    };
  },
  async mounted() {},
  methods: {
    onSubmit(value) {
      store
        .dispatch("formulasModule/postSingleRation", {
          formula: value,
          plantid: this.$store.state.app.selectedPlantId,
        })
        .then((val) => {
          if (val.status == false)
            if (val.errorCode == -1)
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("unsuccessful"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: this.$t("codeAlreadyExists"),
                },
              });
            else
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("unsuccessful"),
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: this.$t("nameAlreadyExists"),
                },
              });
          else {
            this.$emit("refetch-data");
            this.$emit("update:is-add-new-formula-sidebar-active", false);
          }
        });
    },
    change(val) {
      this.$emit("update:is-add-new-formula-sidebar-active", val);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-formula-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
