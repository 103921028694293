<template>
  <b-sidebar
    id="edit-formula-sidebar"
    :visible="isEditFormulaSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="formValidation(resetblankformula).resetForm"
    @change="(val) => changed(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("formulaEdit") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblankformula
    ).refFormObserver"
      >
        <component
          :is="TinyFormulaAdd"
          :key="formula.id"
          :handle-submit="handleSubmit"
          :hide="hide"
          :form-validation="formValidation"
          :ration="formula"
          :is-edit="true"
          @onSubmit="onSubmit"
        ></component>
        <!-- Form -->
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { getUserData } from "@/auth/utils";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TinyFormulaAdd from "../FormulaAddForm/TinyFormulaAdd.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

    BCardActions,
    TinyFormulaAdd,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditFormulaSidebarActive",
    event: "update:is-edit-formula-sidebar-active",
  },
  props: {
    isEditFormulaSidebarActive: {
      type: Boolean,
      required: true,
    },
    formula: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      TinyFormulaAdd,
      required,
      blankFormulaData: {},
      formValidation: formValidation,
    };
  },

  methods: {
    async changed(val) {
      if (val == true) this.blankFormulaData = this.formula;
      this.$emit("update:is-edit-formula-sidebar-active", val);
    },
    onSubmit(formulaData) {
      store
        .dispatch("formulasModule/updateSingleFormula", {
          formula: {
            id: formulaData.id,
            code: formulaData.code,
            name: formulaData.rationname,
          },
          plantid: this.$store.state.app.selectedPlantId,
        })
        .then((isOk) => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-formula-sidebar-active", false);
          if (isOk)
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$t("successful"),
                icon: "CheckIcon",
                variant: "success",
                text: this.$t("updated", { type: this.$t("formula") }),
              },
            });
          else
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$t("successful"),
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: this.$t("updated", { type: this.$t("formula") }),
              },
            });
        });
    },
    async resetblankformula() {
      blankFormulaData = {};
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-formula-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
