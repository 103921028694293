<template>
  <b-modal
    id="modalFormulaCopy"
    scrollable
    :title="$t('formulaCopy')"
    cancel-title="Close"
    :ok-title="$t('ok')"
    ok-only
    centered
    @ok="submitFormula"
  >
    <span class="font-weight-bold"> {{ $t("copiedFormula") }}: </span>
    <p>({{ formula.code }}) {{ formula.rationname }}</p>
    <b-form-group class="mt-2" :label="$t('code')">
      <b-form-input v-model="newCode" />
    </b-form-group>
    <b-form-group class="mt-2" :label="$t('formulaName')">
      <b-form-input v-model="newName" />
    </b-form-group>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BTable,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
  },
  props: {
    selectedFormula: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      formula: {},
      newName: "",
      newCode: "",
    };
  },
  watch: {
    selectedFormula: function (val) {
      this.formula = JSON.parse(JSON.stringify(val));

      this.newName = "";
      this.newCode = "";
    },
  },
  mounted: function () {
    this.formula = JSON.parse(JSON.stringify(this.selectedFormula));
  },
  methods: {
    submitFormula() {
      this.formula.formulaname = this.newName;
      this.formula.kod = this.newCode;
      this.$emit("copy-formula", this.formula);
    },
  },
};
</script>
