import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import dayjs from "dayjs";
import tr from "dayjs/locale/tr";
import az from "dayjs/locale/az";
import ru from "dayjs/locale/ru";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueI18n from "@/libs/i18n";
import { getUserData } from "@/auth/utils";

export default function useFormulasList() {
  // Use toast
  const toast = useToast();

  const refFormulaListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "preview", label: VueI18n.t("view") },
    { key: "code", label: VueI18n.t("code") },
    { key: "rationname", label: VueI18n.t("formulaName"), sortable: false },
    {
      key: "formulaDate",
      label: VueI18n.t("formulaDate"),
      sortable: false,
      formatter: (val) =>
        `${dayjs(val)
          .locale(`${VueI18n.locale}`)
          .format("DD MMMM YYYY HH:mm:ss")}`,
    },
    {
      key: "fiyat",
      label: VueI18n.t("price"),
      sortable: false,
      formatter: (val) =>
        `${parseFloat(val).toFixed(getUserData().pricedecimal)} ${
          getUserData().currency
        }`,
      class: "text-right",
    },

    { key: "lastUser", label: VueI18n.t("lastUser") },
    { key: "copy", label: VueI18n.t("copy") },
    { key: "edit", label: VueI18n.t("edit") },
    { key: "delete", label: VueI18n.t("delete") },
  ];
  const perPage = ref(10);
  const totalFormulas = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refFormulaListTable.value
      ? refFormulaListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFormulas.value,
    };
  });

  const refetchData = () => {
    refFormulaListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData();
  });

  const fetchFormulas = async (ctx, callback) => {
    var orders = await store
      .dispatch("formulasModule/fetchFormulas", {
        tur: "singleFormula",
        plantid: store.state.app.selectedPlantId,
      })

      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("formulas") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    return orders;
  };

  const deleteFormula = async (ctx) => {
    await store.dispatch("formulasModule/deleteFormula", {
      formulaid: ctx.id,
      plantid: ctx.plantid,
      tur: ctx.tur,
    });
    refetchData();
  };
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const copyFormula = async (ctx) => {
    var response = await store.dispatch("formulasModule/copyFormula", {
      sourcerationid: ctx.id,
      code: ctx.kod,
      rationname: ctx.formulaname,
    });
    if (response.status == "Error")
      if (response.statusdata == "-1")
        toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "CheckIcon",
            variant: "danger",
            text: VueI18n.t("codeAlreadyExists"),
          },
        });
      else if (response.statusdata == "-2")
        toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: VueI18n.t("nameAlreadyExists"),
          },
        });
      else
        toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: VueI18n.t("thisNameUsedOnIngredient"),
          },
        });

    refetchData();
  };
  return {
    fetchFormulas,
    tableColumns,
    perPage,
    currentPage,
    totalFormulas,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFormulaListTable,
    deleteFormula,
    statusFilter,
    copyFormula,
    refetchData,
  };
}
