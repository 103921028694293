import { render, staticRenderFns } from "./FormulaList.vue?vue&type=template&id=df313aea&scoped=true"
import script from "./FormulaList.vue?vue&type=script&lang=js"
export * from "./FormulaList.vue?vue&type=script&lang=js"
import style0 from "./FormulaList.vue?vue&type=style&index=0&id=df313aea&prod&lang=scss&scoped=true"
import style1 from "./FormulaList.vue?vue&type=style&index=1&id=df313aea&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df313aea",
  null
  
)

export default component.exports